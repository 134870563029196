.dtmb-download {
  margin-top: 64px;
  margin-bottom: 64px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
}

.dtmb-download .download-badge {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 336px;
  height: auto;
}

@media only screen and (min-width: 768px) {
  .dtmb-download .download-badge {
    width: auto;
    height: 100px;
  }
}
