:root {
  --header-bg-color: #111111;
  --header-height: 96px;
}

header.app-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--header-height);
  background-color: var(--header-bg-color);
}

header.app-header .logo {
  margin-left: 20px;
  font-size: 2.2em;
  font-family: KidPixies;
}

header.app-header a {
  color: var(--foreground-color);
  text-decoration: none;
}

header.app-header a:hover {
  text-decoration: underline;
}

header.app-header label {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

header.app-header label .menu-line {
  width: 35px;
  height: 3px;
  background-color: var(--foreground-color);
}

header.app-header label#nav-toggle-label {
  width: var(--header-height);
}

header.app-header input#nav-toggle {
  display: none;
}

header.app-header input#nav-toggle + nav {
  display: none;
  background-color: var(--header-bg-color);
}

header.app-header input#nav-toggle:checked + nav {
  display: block;
  position: absolute;
  right: 0;
  top: var(--header-height);
}

header.app-header nav ul {
  padding-left: 0;
  list-style: none;
}

header.app-header nav li {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 1.5em;
  font-weight: bold;
}

header.app-header nav .active {
  text-decoration: underline;
}

footer.app-footer {
  width: 100%;
  background-color: var(--header-bg-color);
  margin-top: 64px;
  padding-top: 64px;
  padding-bottom: 64px;
}

footer.app-footer p {
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  font-size: 0.9em;
}

footer.app-footer a {
  color: var(--foreground-color);
}

footer.app-footer .social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 64px;
  gap: 32px;
}

footer.app-footer .social img {
  width: auto;
  height: 32px;
}

@media only screen and (min-width: 768px) {
  header.app-header .logo {
    font-size: 3em;
  }

  header.app-header #nav-toggle-label {
    display: none;
  }

  header.app-header input#nav-toggle + nav {
    display: flex;
    align-items: center;
  }

  header.app-header input#nav-toggle:checked + nav {
    position: static;
  }

  header.app-header nav li {
    display: inline-block;
  }

  header.app-header nav li.nav-dropdown-only {
    display: none;
  }

  footer.app-footer .social {
    gap: 48px;
  }
}
