.home h2 {
  text-align: center;
}

.home a {
  text-decoration: none;
}

.home .dtmb-image {
  background-image: url(/src/assets/optimized/dtmb/dtmb-hero-mobile.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 150vw;
}

.home .dtmb-description {
  margin-top: 50px;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}

.home .dtmb-learn-more {
  margin-bottom: 50px;
  padding-left: 0;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  list-style: none;
}

.home .dtmb-learn-more a:hover {
  text-decoration: underline;
}

.home .mailing-list-signup {
  margin-bottom: 64px;
}

.home .post-links {
  margin: 0 auto;
  list-style: none;
  padding-left: 0;
}

.home .post-link {
  border: 1px solid #686868;
  border-radius: 5px;
  background-color: #00000055;
  margin: 50px auto;
  padding: 50px;
  max-width: 668px;
  display: flex;
  flex-flow: row-reverse wrap;
  align-items: center;
  justify-content: space-between;
}

.home .post-link .post-thumbnail {
  margin: 0 auto;
  width: 350px;
}

.home .post-link .post-thumbnail img {
  width: 100%;
  height: auto;
}

.home .post-link .metadata {
  margin: 0 auto;
  width: 400px;
  display: flex;
  flex-flow: column;
  text-align: center;
  color: var(--foreground-color);
}

.home .post-link .post-title {
  font-size: 1.5em;
  font-weight: bold;
  color: var(--link-color);
}

@media only screen and (min-width: 768px) {
  .home .dtmb-image {
    background-image: url(/src/assets/optimized/dtmb/dtmb-hero.jpg);
    height: 640px;
  }

  .home .post-link .post-thumbnail {
    width: 256px;
  }

  .home .post-link .metadata {
    text-align: left;
  }
}
