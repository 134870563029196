#mc_embed_signup {
  margin: 50px auto;
  width: 300px;
}

#mc_embed_signup #mc_embed_signup_scroll {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

#mc_embed_signup input {
  padding: 10px;
  font-size: 15px;
}

#mc_embed_signup .button {
  border: 1px solid lightgray;
  border-radius: 5px;
  background-color: black;
  color: var(--foreground-color);
  font-weight: bold;
  cursor: pointer;
}

#mc_embed_signup p {
  margin-top: 30px;
  text-align: center;
}
