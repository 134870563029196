.dtmb-press-kit h2 {
  text-align: center;
}

.dtmb-press-kit .hero-image {
  background-image: url(/src/assets/optimized/dtmb/dtmb-hero-mobile.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 150vw;
}

.dtmb-press-kit .press-kit-content {
  margin-bottom: 100px;
}

.dtmb-press-kit .press-kit-content h2 {
  text-align: left;
}

.dtmb-press-kit .general-info {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.dtmb-press-kit .general-info-column {
  display: flex;
  flex-direction: column;
}

.dtmb-press-kit .thumbnails {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  gap: 50px;
}

@media only screen and (min-width: 768px) {
  .dtmb-press-kit .hero-image {
    background-image: url(/src/assets/optimized/dtmb/dtmb-hero.jpg);
    height: 640px;
  }

  .dtmb-press-kit .general-info-column {
    max-width: 67%;
  }
}
