.dtmb section {
  margin-top: 128px;
}

.dtmb h2 {
  text-align: center;
}

.dtmb .hero-image {
  background-image: url(/src/assets/optimized/dtmb/dtmb-hero-mobile.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 150vw;
}

.dtmb .game-description {
  margin-bottom: 128px;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
}

.dtmb .action-links {
  margin-bottom: 128px;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}

.dtmb .action-links a {
  text-decoration: none;
}

.dtmb .action-links a:hover {
  text-decoration: underline;
}

.dtmb .highlight-row,
.dtmb .highlight-row-reverse {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 64px;
  margin-bottom: 64px;
  justify-content: space-between;
}

.dtmb .highlight {
  margin: 64px auto 0 auto;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}

.dtmb .highlight ul {
  text-align: left;
}

.dtmb .collaborators {
  margin: 128px auto 64px auto;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
}

.dtmb .collaborators p {
  margin-bottom: 64px;
}

.dtmb .collaborators ul {
  padding-left: 0;
  list-style: none;
}

@media only screen and (min-width: 768px) {
  .dtmb .hero-image {
    background-image: url(/src/assets/optimized/dtmb/dtmb-hero.jpg);
    height: 62vw;
  }

  .dtmb .highlight-row {
    flex-wrap: nowrap;
  }

  .dtmb .highlight-row-reverse {
    flex-flow: row-reverse nowrap;
  }

  .dtmb .highlight-row .highlight {
    text-align: right;
    margin-left: 10px;
    list-style-position: inside;
  }

  .dtmb .highlight-row-reverse .highlight {
    text-align: left;
    margin-right: 10px;
  }

  .dtmb .highlight {
    margin: 0;
  }

  .dtmb .highlight ul {
    text-align: right;
  }
}
