.post h2 {
  text-align: center;
}

.post .metadata {
  margin-bottom: 50px;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-flow: row wrap;
  gap: 25px;
  font-size: 1.1em;
}

.post .metadata address {
  font-style: normal;
}

.post .hero-image {
  width: 100%;
  margin-bottom: 100px;
}

.post .post-content {
  max-width: 768px;
  margin: 0 auto 100px auto;
}

.post .post-content img {
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 100%;
}

.post .post-content figure {
  margin: 0;
  width: 100%;
  text-align: center;
}

.post .post-content figure img {
  margin-bottom: 25px;
}

.post .post-content figcaption {
  font-style: italic;
  margin-bottom: 50px;
}