:root {
  --foreground-color: #d3d3d3;
  --link-color: #ffde00;
  --max-width: 1024px;
}

@font-face {
  font-family: KidPixies;
  src: url(/src/assets/kidpixies-regular.ttf);
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  background-color: #37393d;
  background-image: url(/src/assets/optimized/background.jpg);
  color: var(--foreground-color);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  max-width: var(--max-width);
  min-height: calc(100vh - 537px);
  margin: auto;
  padding: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
}

h1 {
  font-size: 48px;
  text-align: center;
}

h2 {
  margin-top: 64px;
  margin-bottom: 32px;
  font-size: 32px;
}

h3 {
  margin-top: 48px;
  margin-bottom: 24px;
  font-size: 24px;
}

a {
  color: var(--link-color);
  text-decoration: underline;
}

dt {
  font-weight: bold;
}

dt::after {
  content: ":";
}

dd {
  margin-bottom: 1em;
}

hr.section-separator {
  height: 2px;
  border: 0 none;
  background-color: #bbbbbb;
  color: #bbbbbb;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
