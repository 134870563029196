.dtmb-trailer {
  display: flex;
  justify-content: center;
}

.dtmb-trailer .video {
  width: 50.625vh;
  max-width: 100%;
  height: 90vh;
}
